import React from 'react';
import {Container, Grid, makeStyles} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby"
import RechtsgebietCard from "../components/RechtsgebietCard";
import Layout from "../components/Layout";


const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));

export default function App() {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
    {
      rechtsgebieteKommunen: allContentfulRechtsgebiet(filter: {category: {eq: "Beratung für Kommunen"}}, sort: {fields: weight, order: ASC}) {
        rechtsgebiete: nodes {
          slug
          category
          title
          weight
          cardImage {
            fluid(maxWidth: 384, maxHeight: 216, resizingBehavior: FILL, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      allCategories: allContentfulKategorie {
        categories:nodes {
          title
          slug
          heroImage {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }      
    }
  `);

    const currentCategory = data.allCategories.categories.find(category => category.title === 'Beratung für Kommunen');
    const rechtsgebieteTiles = data.rechtsgebieteKommunen.rechtsgebiete.map((rechtsgebiet, i) => {
        return (
            <RechtsgebietCard key={i} rechtsgebiet={rechtsgebiet} categoryPath="beratung-fuer-kommunen"/>
        )
    });

    return (
        <Layout
            heroHeadline1="Beratung für Kommunen"
            heroHeadline2=""
            heroImage={currentCategory.heroImage.fluid}
        >
            <Container className={classes.cardGrid}>
                <Grid container spacing={4}>
                    {rechtsgebieteTiles}
                </Grid>
            </Container>
        </Layout>
    );
}
